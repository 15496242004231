// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Type_Data_Boolean = require("../Type.Data.Boolean/index.js");
var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");
var UseStyles = function (x) {
    return x;
};
var ToClassNamesRl = {};
var ToClassNames = {};
var StartsWithMedia = {};
var Optional = {};
var toClassNamesRlNil = ToClassNamesRl;
var toClassNamesRlConsDirect = function (dictToClassNamesRl) {
    return ToClassNamesRl;
};
var toClassNamesRecord = function (dictRowToList) {
    return function (dictRowToList1) {
        return function (dictToClassNamesRl) {
            return ToClassNames;
        };
    };
};
var testStartMedia = function (dictStartsWithMedia) {
    return function (sym) {
        return Type_Data_Boolean.BProxy.value;
    };
};
var style$prime = Unsafe_Coerce.unsafeCoerce;
var style = function ($13) {
    return style$prime(Data_Function["const"]($13));
};
var startsWithMediaSym = function (dictEquals) {
    return function (dictAppend) {
        return StartsWithMedia;
    };
};
var srInst = function (dictUnion) {
    return Optional;
};
var newtypeUseStyles = new Data_Newtype.Newtype(function (n) {
    return n;
}, UseStyles);
var createUseStyles_ = function (dictToClassNames) {
    return function (dictHomogeneous) {
        return function (classes) {
            return $foreign.createUseStyles()()()(classes)([  ]);
        };
    };
};
module.exports = {
    UseStyles: UseStyles,
    createUseStyles_: createUseStyles_,
    style: style,
    "style'": style$prime,
    ToClassNames: ToClassNames,
    ToClassNamesRl: ToClassNamesRl,
    Optional: Optional,
    StartsWithMedia: StartsWithMedia,
    testStartMedia: testStartMedia,
    newtypeUseStyles: newtypeUseStyles,
    toClassNamesRecord: toClassNamesRecord,
    toClassNamesRlNil: toClassNamesRlNil,
    toClassNamesRlConsDirect: toClassNamesRlConsDirect,
    srInst: srInst,
    startsWithMediaSym: startsWithMediaSym,
    createUseStyles: $foreign.createUseStyles
};
