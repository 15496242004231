// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var React_Basic = require("../React.Basic/index.js");
var Untagged_Coercible = require("../Untagged.Coercible/index.js");
var txt = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_text"])(Untagged_Coercible.coerce()(props));
    };
};
var txt_ = function (children) {
    return txt()({
        children: children
    });
};
var title = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_title"])(Untagged_Coercible.coerce()(props));
    };
};
var title_ = function (children) {
    return title()({
        children: children
    });
};
var p = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_paragraph"])(Untagged_Coercible.coerce()(props));
    };
};
var p_ = function (children) {
    return p()({
        children: children
    });
};
module.exports = {
    title: title,
    title_: title_,
    txt: txt,
    txt_: txt_,
    p: p,
    p_: p_,
    "_title": $foreign["_title"],
    "_text": $foreign["_text"],
    "_paragraph": $foreign["_paragraph"]
};
