const React = require('react');
const AmplifyUi = require('aws-amplify-react');

exports.authenticator = updateUser =>
  React.createElement(AmplifyUi.Authenticator, {
    hide: [AmplifyUi.SignUp],
    usernameAttributes: 'email',
    signUpConfig: { hiddenDefault: ['phone_number'] },
    onStateChange: authState => {
      if (authState === 'signedIn') {
        updateUser();
      }
    },
  });
