// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Control_Promise = require("../Control.Promise/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var signOut = Control_Promise.toAffE($foreign["_signOut"]);
var fromCognitoUser = function (v) {
    return {
        id: v.username,
        email: v.signInUserSession.idToken.payload.email,
        idToken: v.signInUserSession.idToken.jwtToken
    };
};
var currentAuthenticatedUser = Data_Functor.map(Effect_Aff.functorAff)(Data_Functor.map(Data_Functor.functorFn)(Data_Functor.map(Data_Maybe.functorMaybe)(fromCognitoUser))(Data_Either.hush))(Control_Monad_Error_Class["try"](Effect_Aff.monadErrorAff)(Control_Promise.toAffE($foreign["_currentAuthenticatedUser"])));
var authContext = Effect_Unsafe.unsafePerformEffect(React_Basic.createContext({
    user: Data_Maybe.Nothing.value,
    updateAndGetUser: Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Maybe.Nothing.value),
    signOut: Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Unit.unit)
}));
var mkAuthProvider = React_Basic_Hooks.component()()()("UserProvider")(function (v) {
    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(Data_Maybe.Nothing.value))(function (v1) {
        var updateAndGetUser = Control_Bind.bind(Effect_Aff.bindAff)(currentAuthenticatedUser)(function (u) {
            return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(v1.value1(Data_Function["const"](u))))(function () {
                return Control_Applicative.pure(Effect_Aff.applicativeAff)(u);
            });
        });
        var signOut$prime = Control_Apply.applySecond(Effect_Aff.applyAff)(signOut)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(v1.value1(Data_Function["const"](Data_Maybe.Nothing.value))));
        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic.provider(authContext)({
            user: v1.value0,
            updateAndGetUser: updateAndGetUser,
            signOut: signOut$prime
        })(v.content));
    });
});
module.exports = {
    authContext: authContext,
    mkAuthProvider: mkAuthProvider
};
