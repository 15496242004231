// Generated by purs version 0.13.6
"use strict";
var AdminUi_Antd_Button = require("../AdminUi.Antd.Button/index.js");
var AdminUi_Antd_Form = require("../AdminUi.Antd.Form/index.js");
var AdminUi_Antd_Input = require("../AdminUi.Antd.Input/index.js");
var AdminUi_Antd_InputNumber = require("../AdminUi.Antd.InputNumber/index.js");
var AdminUi_Antd_PropLits = require("../AdminUi.Antd.PropLits/index.js");
var AdminUi_Antd_Select = require("../AdminUi.Antd.Select/index.js");
var AdminUi_Antd_Typography = require("../AdminUi.Antd.Typography/index.js");
var AdminUi_Spacer = require("../AdminUi.Spacer/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Jss_ReactJss = require("../Jss.ReactJss/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var mkCreateSiloInvitation = (function () {
    var useStyles = Jss_ReactJss.createUseStyles_()()({
        namesContainer: Jss_ReactJss.style({
            display: "flex",
            justifyContent: "space-between"
        }),
        nameFormItem: Jss_ReactJss.style({
            flexBasis: "48%"
        }),
        submitButtonContainer: Jss_ReactJss.style({
            display: "flex",
            margin: {
                bottom: "20px"
            }
        })
    });
    return React_Basic_Hooks.component()()()("CreateSiloInvitation")(function (v) {
        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(useStyles({}))(function (v1) {
            return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_DOM_Generated.div()({
                children: [ AdminUi_Antd_Typography.title()({
                    level: AdminUi_Antd_PropLits.two,
                    children: "Create New Silo Invitation"
                }), AdminUi_Antd_Form.form()({
                    colon: false,
                    layout: AdminUi_Antd_PropLits.vertical,
                    children: [ AdminUi_Antd_Typography.title()({
                        level: AdminUi_Antd_PropLits.three,
                        children: "Silo Information"
                    }), AdminUi_Antd_Form.formItem()({
                        label: "Silo Code",
                        children: AdminUi_Antd_Input.input()({})
                    }), AdminUi_Antd_Form.formItem()({
                        label: "Sender ID",
                        children: AdminUi_Antd_Input.input()({})
                    }), AdminUi_Antd_Form.formItem()({
                        label: "Currency Name",
                        children: AdminUi_Antd_Input.input()({})
                    }), AdminUi_Antd_Form.formItem()({
                        label: "Branch Limit",
                        children: AdminUi_Antd_InputNumber.inputNumber()({})
                    }), AdminUi_Antd_Form.formItem()({
                        label: "Locale",
                        children: AdminUi_Antd_Select.select()({
                            allowClear: true,
                            children: [ AdminUi_Antd_Select.option()({
                                value: "Singapore",
                                children: "Singapore"
                            }), AdminUi_Antd_Select.option()({
                                value: "Malaysia",
                                children: "Malaysia"
                            }), AdminUi_Antd_Select.option()({
                                value: "Philippines",
                                children: "Philippines"
                            }), AdminUi_Antd_Select.option()({
                                value: "Guam",
                                children: "Guam"
                            }) ]
                        })
                    }), AdminUi_Spacer.vspacer(8), AdminUi_Antd_Typography.title()({
                        level: AdminUi_Antd_PropLits.three,
                        children: "Business Owner"
                    }), React_Basic_DOM_Generated.div()({
                        className: v1.namesContainer,
                        children: [ AdminUi_Antd_Form.formItem()({
                            label: "First Name",
                            children: AdminUi_Antd_Input.input()({}),
                            className: v1.nameFormItem
                        }), AdminUi_Antd_Form.formItem()({
                            label: "Last Name",
                            children: AdminUi_Antd_Input.input()({}),
                            className: v1.nameFormItem
                        }) ]
                    }), AdminUi_Antd_Form.formItem()({
                        label: "Email",
                        children: AdminUi_Antd_Input.input()({})
                    }), React_Basic_DOM_Generated.div()({
                        className: v1.submitButtonContainer,
                        children: [ AdminUi_Antd_Button.button()({
                            type: AdminUi_Antd_PropLits.primary,
                            loading: false,
                            children: [ React_Basic_DOM.text("Submit") ]
                        }), AdminUi_Spacer.hspacer(2), AdminUi_Antd_Button.button()({
                            children: [ React_Basic_DOM.text("Cancel") ]
                        }) ]
                    }) ]
                }) ]
            }));
        });
    });
})();
module.exports = {
    mkCreateSiloInvitation: mkCreateSiloInvitation
};
