const _createUseStyles = require('react-jss').createUseStyles;

exports.createUseStyles = () => () => () => baseCssClasses => mediaQueries => {
  const mqs = mediaQueries.reduce((acc, curr) => {
    acc[`@media (min-width: ${curr.minWidth}px)`] = curr.classes;
    return acc;
  }, {});
  const final = Object.assign({}, baseCssClasses, mqs);
  const useStyles = _createUseStyles(final);
  return props => () => {
    return useStyles(props);
  };
};
