// Generated by purs version 0.13.6
"use strict";
var Data_Int = require("../Data.Int/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Int$prime = {};
var Int = {};
var Digit = {};
var signPartEmpty = function (dictInt$prime) {
    return Int$prime;
};
var signPart = function (dictCons) {
    return function (dictInt$prime) {
        return Int$prime;
    };
};
var lastIntegerPart = function (dictDigit) {
    return Int$prime;
};
var intLit = function (dictIsSymbol) {
    return function (dictInt) {
        return Data_Maybe.fromJust()(Data_Int.fromString(Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value)));
    };
};
var intInstance = function (dictInt$prime) {
    return function (dictCons) {
        return Int;
    };
};
var digitIntegerPart = function (dictDigit) {
    return function (dictCons) {
        return function (dictInt$prime) {
            return Int$prime;
        };
    };
};
var digit9 = Digit;
var digit8 = Digit;
var digit7 = Digit;
var digit6 = Digit;
var digit5 = Digit;
var digit4 = Digit;
var digit3 = Digit;
var digit2 = Digit;
var digit1 = Digit;
var digit0 = Digit;
module.exports = {
    Digit: Digit,
    Int: Int,
    "Int'": Int$prime,
    intLit: intLit,
    digit0: digit0,
    digit1: digit1,
    digit2: digit2,
    digit3: digit3,
    digit4: digit4,
    digit5: digit5,
    digit6: digit6,
    digit7: digit7,
    digit8: digit8,
    digit9: digit9,
    intInstance: intInstance,
    signPart: signPart,
    signPartEmpty: signPartEmpty,
    lastIntegerPart: lastIntegerPart,
    digitIntegerPart: digitIntegerPart
};
