// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var React_Basic = require("../React.Basic/index.js");
var Untagged_Coercible = require("../Untagged.Coercible/index.js");
var select = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_select"])(Untagged_Coercible.coerce()(props));
    };
};
var option = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_option"])(Untagged_Coercible.coerce()(props));
    };
};
var optGroup = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_optGroup"])(Untagged_Coercible.coerce()(props));
    };
};
module.exports = {
    select: select,
    option: option,
    optGroup: optGroup,
    "_select": $foreign["_select"],
    "_option": $foreign["_option"],
    "_optGroup": $foreign["_optGroup"]
};
