import React from 'react';
import { hot } from 'react-hot-loader';
import Amplify from 'aws-amplify';
import { mkAppComponent } from '../../../output/SuperAdmin.Client.App';
import appConfig from '../../../app.json';

Amplify.configure({
  Auth: {
    region: process.env.COGNITO_REGION || appConfig.staging.cognito.region,
    userPoolId:
      process.env.COGNITO_USER_POOL_ID || appConfig.staging.cognito.userPoolId,
    userPoolWebClientId:
      process.env.COGNITO_USER_POOL_WEB_CLIENT_ID ||
      appConfig.staging.cognito.userPoolWebClientId,
  },
});

const App = mkAppComponent();

function Root() {
  return <App />;
}

export default hot(module)(Root);
