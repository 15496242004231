// Generated by purs version 0.13.6
"use strict";
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Internal = require("../React.Basic.DOM.Internal/index.js");
var wbr$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("wbr");
};
var wbr = function (dictUnion) {
    return React_Basic.element(wbr$prime());
};
var video$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("video");
};
var video = function (dictUnion) {
    return React_Basic.element(video$prime());
};
var video_ = function (children) {
    return video()({
        children: children
    });
};
var var$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("var");
};
var $$var = function (dictUnion) {
    return React_Basic.element(var$prime());
};
var var_ = function (children) {
    return $$var()({
        children: children
    });
};
var ul$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("ul");
};
var ul = function (dictUnion) {
    return React_Basic.element(ul$prime());
};
var ul_ = function (children) {
    return ul()({
        children: children
    });
};
var u$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("u");
};
var u = function (dictUnion) {
    return React_Basic.element(u$prime());
};
var u_ = function (children) {
    return u()({
        children: children
    });
};
var track$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("track");
};
var track = function (dictUnion) {
    return React_Basic.element(track$prime());
};
var tr$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("tr");
};
var tr = function (dictUnion) {
    return React_Basic.element(tr$prime());
};
var tr_ = function (children) {
    return tr()({
        children: children
    });
};
var title$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("title");
};
var title = function (dictUnion) {
    return React_Basic.element(title$prime());
};
var title_ = function (children) {
    return title()({
        children: children
    });
};
var time$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("time");
};
var time = function (dictUnion) {
    return React_Basic.element(time$prime());
};
var time_ = function (children) {
    return time()({
        children: children
    });
};
var thead$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("thead");
};
var thead = function (dictUnion) {
    return React_Basic.element(thead$prime());
};
var thead_ = function (children) {
    return thead()({
        children: children
    });
};
var th$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("th");
};
var th = function (dictUnion) {
    return React_Basic.element(th$prime());
};
var th_ = function (children) {
    return th()({
        children: children
    });
};
var tfoot$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("tfoot");
};
var tfoot = function (dictUnion) {
    return React_Basic.element(tfoot$prime());
};
var tfoot_ = function (children) {
    return tfoot()({
        children: children
    });
};
var textarea$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("textarea");
};
var textarea = function (dictUnion) {
    return React_Basic.element(textarea$prime());
};
var textarea_ = function (children) {
    return textarea()({
        children: children
    });
};
var template$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("template");
};
var template = function (dictUnion) {
    return React_Basic.element(template$prime());
};
var template_ = function (children) {
    return template()({
        children: children
    });
};
var td$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("td");
};
var td = function (dictUnion) {
    return React_Basic.element(td$prime());
};
var td_ = function (children) {
    return td()({
        children: children
    });
};
var tbody$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("tbody");
};
var tbody = function (dictUnion) {
    return React_Basic.element(tbody$prime());
};
var tbody_ = function (children) {
    return tbody()({
        children: children
    });
};
var table$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("table");
};
var table = function (dictUnion) {
    return React_Basic.element(table$prime());
};
var table_ = function (children) {
    return table()({
        children: children
    });
};
var sup$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("sup");
};
var sup = function (dictUnion) {
    return React_Basic.element(sup$prime());
};
var sup_ = function (children) {
    return sup()({
        children: children
    });
};
var summary$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("summary");
};
var summary = function (dictUnion) {
    return React_Basic.element(summary$prime());
};
var summary_ = function (children) {
    return summary()({
        children: children
    });
};
var sub$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("sub");
};
var sub = function (dictUnion) {
    return React_Basic.element(sub$prime());
};
var sub_ = function (children) {
    return sub()({
        children: children
    });
};
var style$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("style");
};
var style = function (dictUnion) {
    return React_Basic.element(style$prime());
};
var style_ = function (children) {
    return style()({
        children: children
    });
};
var strong$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("strong");
};
var strong = function (dictUnion) {
    return React_Basic.element(strong$prime());
};
var strong_ = function (children) {
    return strong()({
        children: children
    });
};
var span$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("span");
};
var span = function (dictUnion) {
    return React_Basic.element(span$prime());
};
var span_ = function (children) {
    return span()({
        children: children
    });
};
var source$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("source");
};
var source = function (dictUnion) {
    return React_Basic.element(source$prime());
};
var small$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("small");
};
var small = function (dictUnion) {
    return React_Basic.element(small$prime());
};
var small_ = function (children) {
    return small()({
        children: children
    });
};
var slot$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("slot");
};
var slot = function (dictUnion) {
    return React_Basic.element(slot$prime());
};
var slot_ = function (children) {
    return slot()({
        children: children
    });
};
var select$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("select");
};
var select = function (dictUnion) {
    return React_Basic.element(select$prime());
};
var select_ = function (children) {
    return select()({
        children: children
    });
};
var section$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("section");
};
var section = function (dictUnion) {
    return React_Basic.element(section$prime());
};
var section_ = function (children) {
    return section()({
        children: children
    });
};
var script$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("script");
};
var script = function (dictUnion) {
    return React_Basic.element(script$prime());
};
var script_ = function (children) {
    return script()({
        children: children
    });
};
var samp$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("samp");
};
var samp = function (dictUnion) {
    return React_Basic.element(samp$prime());
};
var samp_ = function (children) {
    return samp()({
        children: children
    });
};
var s$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("s");
};
var s = function (dictUnion) {
    return React_Basic.element(s$prime());
};
var s_ = function (children) {
    return s()({
        children: children
    });
};
var ruby$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("ruby");
};
var ruby = function (dictUnion) {
    return React_Basic.element(ruby$prime());
};
var ruby_ = function (children) {
    return ruby()({
        children: children
    });
};
var rtc$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("rtc");
};
var rtc = function (dictUnion) {
    return React_Basic.element(rtc$prime());
};
var rtc_ = function (children) {
    return rtc()({
        children: children
    });
};
var rt$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("rt");
};
var rt = function (dictUnion) {
    return React_Basic.element(rt$prime());
};
var rt_ = function (children) {
    return rt()({
        children: children
    });
};
var rp$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("rp");
};
var rp = function (dictUnion) {
    return React_Basic.element(rp$prime());
};
var rp_ = function (children) {
    return rp()({
        children: children
    });
};
var rb$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("rb");
};
var rb = function (dictUnion) {
    return React_Basic.element(rb$prime());
};
var rb_ = function (children) {
    return rb()({
        children: children
    });
};
var q$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("q");
};
var q = function (dictUnion) {
    return React_Basic.element(q$prime());
};
var q_ = function (children) {
    return q()({
        children: children
    });
};
var progress$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("progress");
};
var progress = function (dictUnion) {
    return React_Basic.element(progress$prime());
};
var progress_ = function (children) {
    return progress()({
        children: children
    });
};
var pre$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("pre");
};
var pre = function (dictUnion) {
    return React_Basic.element(pre$prime());
};
var pre_ = function (children) {
    return pre()({
        children: children
    });
};
var picture$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("picture");
};
var picture = function (dictUnion) {
    return React_Basic.element(picture$prime());
};
var picture_ = function (children) {
    return picture()({
        children: children
    });
};
var param$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("param");
};
var param = function (dictUnion) {
    return React_Basic.element(param$prime());
};
var p$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("p");
};
var p = function (dictUnion) {
    return React_Basic.element(p$prime());
};
var p_ = function (children) {
    return p()({
        children: children
    });
};
var output$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("output");
};
var output = function (dictUnion) {
    return React_Basic.element(output$prime());
};
var output_ = function (children) {
    return output()({
        children: children
    });
};
var option$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("option");
};
var option = function (dictUnion) {
    return React_Basic.element(option$prime());
};
var option_ = function (children) {
    return option()({
        children: children
    });
};
var optgroup$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("optgroup");
};
var optgroup = function (dictUnion) {
    return React_Basic.element(optgroup$prime());
};
var optgroup_ = function (children) {
    return optgroup()({
        children: children
    });
};
var ol$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("ol");
};
var ol = function (dictUnion) {
    return React_Basic.element(ol$prime());
};
var ol_ = function (children) {
    return ol()({
        children: children
    });
};
var object$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("object");
};
var object = function (dictUnion) {
    return React_Basic.element(object$prime());
};
var object_ = function (children) {
    return object()({
        children: children
    });
};
var noscript$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("noscript");
};
var noscript = function (dictUnion) {
    return React_Basic.element(noscript$prime());
};
var noscript_ = function (children) {
    return noscript()({
        children: children
    });
};
var nav$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("nav");
};
var nav = function (dictUnion) {
    return React_Basic.element(nav$prime());
};
var nav_ = function (children) {
    return nav()({
        children: children
    });
};
var meter$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("meter");
};
var meter = function (dictUnion) {
    return React_Basic.element(meter$prime());
};
var meter_ = function (children) {
    return meter()({
        children: children
    });
};
var meta$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("meta");
};
var meta = function (dictUnion) {
    return React_Basic.element(meta$prime());
};
var menuitem$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("menuitem");
};
var menuitem = function (dictUnion) {
    return React_Basic.element(menuitem$prime());
};
var menuitem_ = function (children) {
    return menuitem()({
        children: children
    });
};
var menu$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("menu");
};
var menu = function (dictUnion) {
    return React_Basic.element(menu$prime());
};
var menu_ = function (children) {
    return menu()({
        children: children
    });
};
var math$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("math");
};
var math = function (dictUnion) {
    return React_Basic.element(math$prime());
};
var math_ = function (children) {
    return math()({
        children: children
    });
};
var mark$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("mark");
};
var mark = function (dictUnion) {
    return React_Basic.element(mark$prime());
};
var mark_ = function (children) {
    return mark()({
        children: children
    });
};
var map$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("map");
};
var map = function (dictUnion) {
    return React_Basic.element(map$prime());
};
var map_ = function (children) {
    return map()({
        children: children
    });
};
var main$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("main");
};
var main = function (dictUnion) {
    return React_Basic.element(main$prime());
};
var main_ = function (children) {
    return main()({
        children: children
    });
};
var link$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("link");
};
var link = function (dictUnion) {
    return React_Basic.element(link$prime());
};
var li$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("li");
};
var li = function (dictUnion) {
    return React_Basic.element(li$prime());
};
var li_ = function (children) {
    return li()({
        children: children
    });
};
var legend$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("legend");
};
var legend = function (dictUnion) {
    return React_Basic.element(legend$prime());
};
var legend_ = function (children) {
    return legend()({
        children: children
    });
};
var label$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("label");
};
var label = function (dictUnion) {
    return React_Basic.element(label$prime());
};
var label_ = function (children) {
    return label()({
        children: children
    });
};
var keygen$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("keygen");
};
var keygen = function (dictUnion) {
    return React_Basic.element(keygen$prime());
};
var keygen_ = function (children) {
    return keygen()({
        children: children
    });
};
var kbd$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("kbd");
};
var kbd = function (dictUnion) {
    return React_Basic.element(kbd$prime());
};
var kbd_ = function (children) {
    return kbd()({
        children: children
    });
};
var ins$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("ins");
};
var ins = function (dictUnion) {
    return React_Basic.element(ins$prime());
};
var ins_ = function (children) {
    return ins()({
        children: children
    });
};
var input$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("input");
};
var input = function (dictUnion) {
    return React_Basic.element(input$prime());
};
var img$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("img");
};
var img = function (dictUnion) {
    return React_Basic.element(img$prime());
};
var iframe$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("iframe");
};
var iframe = function (dictUnion) {
    return React_Basic.element(iframe$prime());
};
var iframe_ = function (children) {
    return iframe()({
        children: children
    });
};
var i$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("i");
};
var i = function (dictUnion) {
    return React_Basic.element(i$prime());
};
var i_ = function (children) {
    return i()({
        children: children
    });
};
var html$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("html");
};
var html = function (dictUnion) {
    return React_Basic.element(html$prime());
};
var html_ = function (children) {
    return html()({
        children: children
    });
};
var hr$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("hr");
};
var hr = function (dictUnion) {
    return React_Basic.element(hr$prime());
};
var hgroup$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("hgroup");
};
var hgroup = function (dictUnion) {
    return React_Basic.element(hgroup$prime());
};
var hgroup_ = function (children) {
    return hgroup()({
        children: children
    });
};
var header$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("header");
};
var header = function (dictUnion) {
    return React_Basic.element(header$prime());
};
var header_ = function (children) {
    return header()({
        children: children
    });
};
var head$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("head");
};
var head = function (dictUnion) {
    return React_Basic.element(head$prime());
};
var head_ = function (children) {
    return head()({
        children: children
    });
};
var h6$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("h6");
};
var h6 = function (dictUnion) {
    return React_Basic.element(h6$prime());
};
var h6_ = function (children) {
    return h6()({
        children: children
    });
};
var h5$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("h5");
};
var h5 = function (dictUnion) {
    return React_Basic.element(h5$prime());
};
var h5_ = function (children) {
    return h5()({
        children: children
    });
};
var h4$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("h4");
};
var h4 = function (dictUnion) {
    return React_Basic.element(h4$prime());
};
var h4_ = function (children) {
    return h4()({
        children: children
    });
};
var h3$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("h3");
};
var h3 = function (dictUnion) {
    return React_Basic.element(h3$prime());
};
var h3_ = function (children) {
    return h3()({
        children: children
    });
};
var h2$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("h2");
};
var h2 = function (dictUnion) {
    return React_Basic.element(h2$prime());
};
var h2_ = function (children) {
    return h2()({
        children: children
    });
};
var h1$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("h1");
};
var h1 = function (dictUnion) {
    return React_Basic.element(h1$prime());
};
var h1_ = function (children) {
    return h1()({
        children: children
    });
};
var form$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("form");
};
var form = function (dictUnion) {
    return React_Basic.element(form$prime());
};
var form_ = function (children) {
    return form()({
        children: children
    });
};
var footer$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("footer");
};
var footer = function (dictUnion) {
    return React_Basic.element(footer$prime());
};
var footer_ = function (children) {
    return footer()({
        children: children
    });
};
var figure$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("figure");
};
var figure = function (dictUnion) {
    return React_Basic.element(figure$prime());
};
var figure_ = function (children) {
    return figure()({
        children: children
    });
};
var figcaption$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("figcaption");
};
var figcaption = function (dictUnion) {
    return React_Basic.element(figcaption$prime());
};
var figcaption_ = function (children) {
    return figcaption()({
        children: children
    });
};
var fieldset$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("fieldset");
};
var fieldset = function (dictUnion) {
    return React_Basic.element(fieldset$prime());
};
var fieldset_ = function (children) {
    return fieldset()({
        children: children
    });
};
var embed$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("embed");
};
var embed = function (dictUnion) {
    return React_Basic.element(embed$prime());
};
var em$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("em");
};
var em = function (dictUnion) {
    return React_Basic.element(em$prime());
};
var em_ = function (children) {
    return em()({
        children: children
    });
};
var dt$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("dt");
};
var dt = function (dictUnion) {
    return React_Basic.element(dt$prime());
};
var dt_ = function (children) {
    return dt()({
        children: children
    });
};
var dl$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("dl");
};
var dl = function (dictUnion) {
    return React_Basic.element(dl$prime());
};
var dl_ = function (children) {
    return dl()({
        children: children
    });
};
var div$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("div");
};
var div = function (dictUnion) {
    return React_Basic.element(div$prime());
};
var div_ = function (children) {
    return div()({
        children: children
    });
};
var dialog$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("dialog");
};
var dialog = function (dictUnion) {
    return React_Basic.element(dialog$prime());
};
var dialog_ = function (children) {
    return dialog()({
        children: children
    });
};
var dfn$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("dfn");
};
var dfn = function (dictUnion) {
    return React_Basic.element(dfn$prime());
};
var dfn_ = function (children) {
    return dfn()({
        children: children
    });
};
var details$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("details");
};
var details = function (dictUnion) {
    return React_Basic.element(details$prime());
};
var details_ = function (children) {
    return details()({
        children: children
    });
};
var del$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("del");
};
var del = function (dictUnion) {
    return React_Basic.element(del$prime());
};
var del_ = function (children) {
    return del()({
        children: children
    });
};
var dd$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("dd");
};
var dd = function (dictUnion) {
    return React_Basic.element(dd$prime());
};
var dd_ = function (children) {
    return dd()({
        children: children
    });
};
var datalist$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("datalist");
};
var datalist = function (dictUnion) {
    return React_Basic.element(datalist$prime());
};
var datalist_ = function (children) {
    return datalist()({
        children: children
    });
};
var data$prime$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("data");
};
var data$prime = function (dictUnion) {
    return React_Basic.element(data$prime$prime());
};
var data_ = function (children) {
    return data$prime()({
        children: children
    });
};
var colgroup$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("colgroup");
};
var colgroup = function (dictUnion) {
    return React_Basic.element(colgroup$prime());
};
var colgroup_ = function (children) {
    return colgroup()({
        children: children
    });
};
var col$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("col");
};
var col = function (dictUnion) {
    return React_Basic.element(col$prime());
};
var code$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("code");
};
var code = function (dictUnion) {
    return React_Basic.element(code$prime());
};
var code_ = function (children) {
    return code()({
        children: children
    });
};
var cite$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("cite");
};
var cite = function (dictUnion) {
    return React_Basic.element(cite$prime());
};
var cite_ = function (children) {
    return cite()({
        children: children
    });
};
var caption$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("caption");
};
var caption = function (dictUnion) {
    return React_Basic.element(caption$prime());
};
var caption_ = function (children) {
    return caption()({
        children: children
    });
};
var canvas$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("canvas");
};
var canvas = function (dictUnion) {
    return React_Basic.element(canvas$prime());
};
var canvas_ = function (children) {
    return canvas()({
        children: children
    });
};
var button$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("button");
};
var button = function (dictUnion) {
    return React_Basic.element(button$prime());
};
var button_ = function (children) {
    return button()({
        children: children
    });
};
var br$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("br");
};
var br = function (dictUnion) {
    return React_Basic.element(br$prime());
};
var body$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("body");
};
var body = function (dictUnion) {
    return React_Basic.element(body$prime());
};
var body_ = function (children) {
    return body()({
        children: children
    });
};
var blockquote$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("blockquote");
};
var blockquote = function (dictUnion) {
    return React_Basic.element(blockquote$prime());
};
var blockquote_ = function (children) {
    return blockquote()({
        children: children
    });
};
var bdo$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("bdo");
};
var bdo = function (dictUnion) {
    return React_Basic.element(bdo$prime());
};
var bdo_ = function (children) {
    return bdo()({
        children: children
    });
};
var bdi$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("bdi");
};
var bdi = function (dictUnion) {
    return React_Basic.element(bdi$prime());
};
var bdi_ = function (children) {
    return bdi()({
        children: children
    });
};
var base$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("base");
};
var base = function (dictUnion) {
    return React_Basic.element(base$prime());
};
var b$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("b");
};
var b = function (dictUnion) {
    return React_Basic.element(b$prime());
};
var b_ = function (children) {
    return b()({
        children: children
    });
};
var audio$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("audio");
};
var audio = function (dictUnion) {
    return React_Basic.element(audio$prime());
};
var audio_ = function (children) {
    return audio()({
        children: children
    });
};
var aside$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("aside");
};
var aside = function (dictUnion) {
    return React_Basic.element(aside$prime());
};
var aside_ = function (children) {
    return aside()({
        children: children
    });
};
var article$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("article");
};
var article = function (dictUnion) {
    return React_Basic.element(article$prime());
};
var article_ = function (children) {
    return article()({
        children: children
    });
};
var area$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("area");
};
var area = function (dictUnion) {
    return React_Basic.element(area$prime());
};
var address$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("address");
};
var address = function (dictUnion) {
    return React_Basic.element(address$prime());
};
var address_ = function (children) {
    return address()({
        children: children
    });
};
var abbr$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("abbr");
};
var abbr = function (dictUnion) {
    return React_Basic.element(abbr$prime());
};
var abbr_ = function (children) {
    return abbr()({
        children: children
    });
};
var a$prime = function (dictUnion) {
    return React_Basic_DOM_Internal.unsafeCreateDOMComponent("a");
};
var a = function (dictUnion) {
    return React_Basic.element(a$prime());
};
var a_ = function (children) {
    return a()({
        children: children
    });
};
module.exports = {
    a: a,
    "a'": a$prime,
    a_: a_,
    abbr: abbr,
    "abbr'": abbr$prime,
    abbr_: abbr_,
    address: address,
    "address'": address$prime,
    address_: address_,
    area: area,
    "area'": area$prime,
    article: article,
    "article'": article$prime,
    article_: article_,
    aside: aside,
    "aside'": aside$prime,
    aside_: aside_,
    audio: audio,
    "audio'": audio$prime,
    audio_: audio_,
    b: b,
    "b'": b$prime,
    b_: b_,
    base: base,
    "base'": base$prime,
    bdi: bdi,
    "bdi'": bdi$prime,
    bdi_: bdi_,
    bdo: bdo,
    "bdo'": bdo$prime,
    bdo_: bdo_,
    blockquote: blockquote,
    "blockquote'": blockquote$prime,
    blockquote_: blockquote_,
    body: body,
    "body'": body$prime,
    body_: body_,
    br: br,
    "br'": br$prime,
    button: button,
    "button'": button$prime,
    button_: button_,
    canvas: canvas,
    "canvas'": canvas$prime,
    canvas_: canvas_,
    caption: caption,
    "caption'": caption$prime,
    caption_: caption_,
    cite: cite,
    "cite'": cite$prime,
    cite_: cite_,
    code: code,
    "code'": code$prime,
    code_: code_,
    col: col,
    "col'": col$prime,
    colgroup: colgroup,
    "colgroup'": colgroup$prime,
    colgroup_: colgroup_,
    "data'": data$prime,
    "data''": data$prime$prime,
    data_: data_,
    datalist: datalist,
    "datalist'": datalist$prime,
    datalist_: datalist_,
    dd: dd,
    "dd'": dd$prime,
    dd_: dd_,
    del: del,
    "del'": del$prime,
    del_: del_,
    details: details,
    "details'": details$prime,
    details_: details_,
    dfn: dfn,
    "dfn'": dfn$prime,
    dfn_: dfn_,
    dialog: dialog,
    "dialog'": dialog$prime,
    dialog_: dialog_,
    div: div,
    "div'": div$prime,
    div_: div_,
    dl: dl,
    "dl'": dl$prime,
    dl_: dl_,
    dt: dt,
    "dt'": dt$prime,
    dt_: dt_,
    em: em,
    "em'": em$prime,
    em_: em_,
    embed: embed,
    "embed'": embed$prime,
    fieldset: fieldset,
    "fieldset'": fieldset$prime,
    fieldset_: fieldset_,
    figcaption: figcaption,
    "figcaption'": figcaption$prime,
    figcaption_: figcaption_,
    figure: figure,
    "figure'": figure$prime,
    figure_: figure_,
    footer: footer,
    "footer'": footer$prime,
    footer_: footer_,
    form: form,
    "form'": form$prime,
    form_: form_,
    h1: h1,
    "h1'": h1$prime,
    h1_: h1_,
    h2: h2,
    "h2'": h2$prime,
    h2_: h2_,
    h3: h3,
    "h3'": h3$prime,
    h3_: h3_,
    h4: h4,
    "h4'": h4$prime,
    h4_: h4_,
    h5: h5,
    "h5'": h5$prime,
    h5_: h5_,
    h6: h6,
    "h6'": h6$prime,
    h6_: h6_,
    head: head,
    "head'": head$prime,
    head_: head_,
    header: header,
    "header'": header$prime,
    header_: header_,
    hgroup: hgroup,
    "hgroup'": hgroup$prime,
    hgroup_: hgroup_,
    hr: hr,
    "hr'": hr$prime,
    html: html,
    "html'": html$prime,
    html_: html_,
    i: i,
    "i'": i$prime,
    i_: i_,
    iframe: iframe,
    "iframe'": iframe$prime,
    iframe_: iframe_,
    img: img,
    "img'": img$prime,
    input: input,
    "input'": input$prime,
    ins: ins,
    "ins'": ins$prime,
    ins_: ins_,
    kbd: kbd,
    "kbd'": kbd$prime,
    kbd_: kbd_,
    keygen: keygen,
    "keygen'": keygen$prime,
    keygen_: keygen_,
    label: label,
    "label'": label$prime,
    label_: label_,
    legend: legend,
    "legend'": legend$prime,
    legend_: legend_,
    li: li,
    "li'": li$prime,
    li_: li_,
    link: link,
    "link'": link$prime,
    main: main,
    "main'": main$prime,
    main_: main_,
    map: map,
    "map'": map$prime,
    map_: map_,
    mark: mark,
    "mark'": mark$prime,
    mark_: mark_,
    math: math,
    "math'": math$prime,
    math_: math_,
    menu: menu,
    "menu'": menu$prime,
    menu_: menu_,
    menuitem: menuitem,
    "menuitem'": menuitem$prime,
    menuitem_: menuitem_,
    meta: meta,
    "meta'": meta$prime,
    meter: meter,
    "meter'": meter$prime,
    meter_: meter_,
    nav: nav,
    "nav'": nav$prime,
    nav_: nav_,
    noscript: noscript,
    "noscript'": noscript$prime,
    noscript_: noscript_,
    object: object,
    "object'": object$prime,
    object_: object_,
    ol: ol,
    "ol'": ol$prime,
    ol_: ol_,
    optgroup: optgroup,
    "optgroup'": optgroup$prime,
    optgroup_: optgroup_,
    option: option,
    "option'": option$prime,
    option_: option_,
    output: output,
    "output'": output$prime,
    output_: output_,
    p: p,
    "p'": p$prime,
    p_: p_,
    param: param,
    "param'": param$prime,
    picture: picture,
    "picture'": picture$prime,
    picture_: picture_,
    pre: pre,
    "pre'": pre$prime,
    pre_: pre_,
    progress: progress,
    "progress'": progress$prime,
    progress_: progress_,
    q: q,
    "q'": q$prime,
    q_: q_,
    rb: rb,
    "rb'": rb$prime,
    rb_: rb_,
    rp: rp,
    "rp'": rp$prime,
    rp_: rp_,
    rt: rt,
    "rt'": rt$prime,
    rt_: rt_,
    rtc: rtc,
    "rtc'": rtc$prime,
    rtc_: rtc_,
    ruby: ruby,
    "ruby'": ruby$prime,
    ruby_: ruby_,
    s: s,
    "s'": s$prime,
    s_: s_,
    samp: samp,
    "samp'": samp$prime,
    samp_: samp_,
    script: script,
    "script'": script$prime,
    script_: script_,
    section: section,
    "section'": section$prime,
    section_: section_,
    select: select,
    "select'": select$prime,
    select_: select_,
    slot: slot,
    "slot'": slot$prime,
    slot_: slot_,
    small: small,
    "small'": small$prime,
    small_: small_,
    source: source,
    "source'": source$prime,
    span: span,
    "span'": span$prime,
    span_: span_,
    strong: strong,
    "strong'": strong$prime,
    strong_: strong_,
    style: style,
    "style'": style$prime,
    style_: style_,
    sub: sub,
    "sub'": sub$prime,
    sub_: sub_,
    summary: summary,
    "summary'": summary$prime,
    summary_: summary_,
    sup: sup,
    "sup'": sup$prime,
    sup_: sup_,
    table: table,
    "table'": table$prime,
    table_: table_,
    tbody: tbody,
    "tbody'": tbody$prime,
    tbody_: tbody_,
    td: td,
    "td'": td$prime,
    td_: td_,
    template: template,
    "template'": template$prime,
    template_: template_,
    textarea: textarea,
    "textarea'": textarea$prime,
    textarea_: textarea_,
    tfoot: tfoot,
    "tfoot'": tfoot$prime,
    tfoot_: tfoot_,
    th: th,
    "th'": th$prime,
    th_: th_,
    thead: thead,
    "thead'": thead$prime,
    thead_: thead_,
    time: time,
    "time'": time$prime,
    time_: time_,
    title: title,
    "title'": title$prime,
    title_: title_,
    tr: tr,
    "tr'": tr$prime,
    tr_: tr_,
    track: track,
    "track'": track$prime,
    u: u,
    "u'": u$prime,
    u_: u_,
    ul: ul,
    "ul'": ul$prime,
    ul_: ul_,
    "var": $$var,
    "var'": var$prime,
    var_: var_,
    video: video,
    "video'": video$prime,
    video_: video_,
    wbr: wbr,
    "wbr'": wbr$prime
};
