// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var SuperAdmin_Client_Context_Auth = require("../SuperAdmin.Client.Context.Auth/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var mkAuthenticator = React_Basic_Hooks.component()()()("Authenticator")(function (v) {
    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useContext(SuperAdmin_Client_Context_Auth.authContext))(function (v1) {
        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))($foreign.authenticator(Effect_Aff.launchAff_(v1.updateAndGetUser)));
    });
});
module.exports = {
    mkAuthenticator: mkAuthenticator,
    authenticator: $foreign.authenticator
};
