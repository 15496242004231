// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var React_Basic = require("../React.Basic/index.js");
var Untagged_Coercible = require("../Untagged.Coercible/index.js");
var inputPassword = function (dictCoercible) {
    return function (p) {
        return React_Basic.element($foreign["_inputPassword"])(Untagged_Coercible.coerce()(p));
    };
};
var input = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_input"])(Untagged_Coercible.coerce()(props));
    };
};
module.exports = {
    input: input,
    inputPassword: inputPassword,
    "_input": $foreign["_input"],
    "_inputPassword": $foreign["_inputPassword"]
};
