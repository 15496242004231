// Generated by purs version 0.13.6
"use strict";
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_DOM_Internal = require("../React.Basic.DOM.Internal/index.js");
var vspacer = function (x) {
    return React_Basic_DOM_Generated.div()({
        style: React_Basic_DOM_Internal.css({
            width: "100%",
            height: x * 5 | 0
        }),
        children: [  ]
    });
};
var hspacer = function (x) {
    return React_Basic_DOM_Generated.div()({
        style: React_Basic_DOM_Internal.css({
            heigth: "100%",
            width: x * 5 | 0
        }),
        children: [  ]
    });
};
module.exports = {
    vspacer: vspacer,
    hspacer: hspacer
};
