// Generated by purs version 0.13.6
"use strict";
var AdminUi_Antd_Button = require("../AdminUi.Antd.Button/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var SuperAdmin_Client_Components_Authenticator = require("../SuperAdmin.Client.Components.Authenticator/index.js");
var SuperAdmin_Client_Context_Auth = require("../SuperAdmin.Client.Context.Auth/index.js");
var SuperAdmin_Client_Screens_SiloInvitation_Create = require("../SuperAdmin.Client.Screens.SiloInvitation.Create/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var mkRoutes = function __do() {
    var authenticator = SuperAdmin_Client_Components_Authenticator.mkAuthenticator();
    var createSiloInvi = SuperAdmin_Client_Screens_SiloInvitation_Create.mkCreateSiloInvitation();
    return React_Basic_Hooks.component()()()("Routes")(function (v) {
        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useContext(SuperAdmin_Client_Context_Auth.authContext))(function (v1) {
            return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))((function () {
                if (v1.user instanceof Data_Maybe.Just) {
                    return React_Basic.fragment([ AdminUi_Antd_Button.button()({
                        onClick: React_Basic_Events.handler_(Effect_Aff.launchAff_(v1.signOut)),
                        children: [ React_Basic_DOM.text("Sign Out") ]
                    }), React_Basic.element(createSiloInvi)({}) ]);
                };
                if (v1.user instanceof Data_Maybe.Nothing) {
                    return React_Basic.element(authenticator)({});
                };
                throw new Error("Failed pattern match at SuperAdmin.Client.App (line 34, column 9 - line 43, column 46): " + [ v1.user.constructor.name ]);
            })());
        });
    })();
};
var mkAppComponent = function __do() {
    var userProvider = SuperAdmin_Client_Context_Auth.mkAuthProvider();
    var routes = mkRoutes();
    return React_Basic_Hooks.component()()()("App")(function (v) {
        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic.element(userProvider)({
            content: Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic.element(routes)({}))
        }));
    })();
};
module.exports = {
    mkAppComponent: mkAppComponent,
    mkRoutes: mkRoutes
};
