import React from 'react';
import ReactDOM from 'react-dom';
import Root from './Root';
import '@zap/admin-ui-components';

ReactDOM.render(<Root />, document.getElementById('root'));

if (module.hot) {
  module.hot.accept();
}
