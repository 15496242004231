// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var Control_Alt = require("../Control.Alt/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Monad_Except = require("../Control.Monad.Except/index.js");
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var Foreign = require("../Foreign/index.js");
var toAff$prime = function (customCoerce) {
    return function (p) {
        return Effect_Aff.makeAff(function (cb) {
            return Data_Functor.voidRight(Effect.functorEffect)(Data_Monoid.mempty(Effect_Aff.monoidCanceler))($foreign.thenImpl(p)(function ($1) {
                return cb(Data_Either.Left.create(customCoerce($1)))();
            })(function ($2) {
                return cb(Data_Either.Right.create($2))();
            }));
        });
    };
};
var fromAff = function (aff) {
    return $foreign.promise(function (succ) {
        return function (err) {
            return Effect_Aff.runAff_(Data_Either.either(err)(succ))(aff);
        };
    });
};
var coerce = function (fn) {
    return Data_Either.either(function (v) {
        return Effect_Exception.error("Promise failed, couldn't extract JS Error or String");
    })(Control_Category.identity(Control_Category.categoryFn))(Control_Monad_Except.runExcept(Control_Alt.alt(Control_Monad_Except_Trans.altExceptT(Data_List_Types.semigroupNonEmptyList)(Data_Identity.monadIdentity))(Foreign.unsafeReadTagged("Error")(fn))(Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Effect_Exception.error)(Foreign.readString(fn)))));
};
var toAff = toAff$prime(coerce);
var toAffE = function (f) {
    return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(f))(toAff);
};
module.exports = {
    fromAff: fromAff,
    toAff: toAff,
    "toAff'": toAff$prime,
    toAffE: toAffE
};
